<template>
  <section>
    <b-row>
      <b-col cols="12">
        <accounts-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCard, BCardText, BRow, BCol } from 'bootstrap-vue'
import AccountsList from '@/views/AccountsList.vue';

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,

    AccountsList,
  },
}
</script>

<style>

</style>
